import React, { useEffect,useState }  from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import _ from "lodash"
import Tabs from "components/CustomTabs/Tabs.js";
import Social from 'features/home/components/Social';
import { default as PriceChart } from "./components/PriceChart";
import { useFetchPrice } from 'features/price/fetchPrice';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
const PricePage = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const { fetchPrice, priceData } = useFetchPrice();
  const [tabValue, setTabValue] = React.useState(0);
  useEffect(() => {
      fetchPrice();

      const id = setInterval(() => {
        fetchPrice();
      }, 1000000);
      return () => clearInterval(id);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
   const renderPrice = (img,header, num, ratio)=>{
    return(
      <div className="card" style={{background:"white", margin:0}}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={3}>
          <img src={require("assets/img/"+img).default} 
              style={{height:35,width:35,objectFit:"contain"}}/>
          </Grid>
          <Grid item xs={9} style={{textAlign:"right"}}>
          <div className="cardHeader" style={{marginBottom:3}}>${num}
            <span className="label" style={{paddingLeft:5}}>{header}</span>
          </div>
          <div>
          <img src={require("assets/img/"+(ratio>0?"up.png":"down.png")).default} 
              style={{height:12,width:22,objectFit:"contain"}}/>
            <span className={ratio>0?"cardGreenValue":"cardRedValue"} style={{paddingLeft:10}}>{parseFloat(ratio).toFixed(2)} %</span>
          </div>
          </Grid>
        </Grid>
   
      </div>
    )
  }
  const renderNews = (img,title, content, source, time)=>{
    return(
      <div className="card" style={{background:"white", margin:"10px 0"}}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={3}>
            <img src={require("assets/img/"+img).default} 
              style={{height:120,width:125,objectFit:"cover",borderRadius:20}}/>
          </Grid>
          <Grid item xs={9} style={{textAlign:"left",padding:5}}>
          <div  style={{marginBottom:3, color:"#0B308E", fontSize:13, fontWeight:500}}>{source}
            <span className="label" style={{paddingLeft:10}}>{time}</span>
          </div>
          <div style={{color:"#404040",fontWeight:600, fontSize:14,marginBottom:10}}>
            <a href={""} target={"_blank"}>{title}</a>
          </div>
          <div className="label">
            {content}
          </div>
          </Grid>
        </Grid>
   
      </div>
    )
  }
  return (
    <div>
      <div className="title">Pricing</div>
      <div className="subTitle">Get summary of your weekly online transactions here.</div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div className="card" >
             {priceData.btc && 
             <Tabs
             tabIndex={tabValue}
             handleChange={handleTabChange}
             tabs={[
               {
                 label: "BTC",
                 content: <PriceChart priceData={priceData.btc}/>,
               },
               {
                 label: "ETH",
                 content: <PriceChart priceData={priceData.eth}/>,
               },
               {
                label: "BNB",
                content: <PriceChart priceData={priceData.bnb}/>,
              },
             ]}
           />
             } 
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <div className="card" style={{backgroundColor:"#A4B5F4"}}>
            <div className="cardHeader" style={{color:"white"}}>Dashboard</div>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} >
                {renderPrice("btc.png","BTC",_.get(priceData,'btcToday.usd',"-"),_.get(priceData,'btcToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("eth.png","ETH",_.get(priceData,'ethToday.usd',"-"),_.get(priceData,'ethToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("bnb.png","BNB",_.get(priceData,'bnbToday.usd',"-"),_.get(priceData,'bnbToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("btc.png","BTC",_.get(priceData,'btcToday.usd',"-"),_.get(priceData,'btcToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("eth.png","ETH",_.get(priceData,'ethToday.usd',"-"),_.get(priceData,'ethToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("bnb.png","BNB",_.get(priceData,'bnbToday.usd',"-"),_.get(priceData,'bnbToday.usd_24h_change',0))}
              </Grid>
     
            </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
          <div className="card" style={{height:345}}>
            <div className="cardHeader">Related News </div>
            {renderNews("news.png","Bitcoin Stuck in a Range, Ethereum Rallies, LUNA and STX Outperform",
            "Get your daily, bite-sized digest of cryptoasset and blockchain-related news.Get your daily, bite-sized digest of cryptoasset and blockchain-related news.",
            "CRYPTONEWS","16 HOURS AGO")}
             {renderNews("news.png","Bitcoin Stuck in a Range, Ethereum Rallies, LUNA and STX Outperform",
            "Get your daily, bite-sized digest of cryptoasset and blockchain-related news.Get your daily, bite-sized digest of cryptoasset and blockchain-related news.",
            "CRYPTONEWS","16 HOURS AGO")}
             {renderNews("news.png","Bitcoin Stuck in a Range, Ethereum Rallies, LUNA and STX Outperform",
            "Get your daily, bite-sized digest of cryptoasset and blockchain-related news.Get your daily, bite-sized digest of cryptoasset and blockchain-related news.",
            "CRYPTONEWS","16 HOURS AGO")}
            </div>
    
          </Grid>
      </Grid>
    </div>
  );
};

export default PricePage;
