import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import axios from "axios";
import _ from "lodash";
export function createUser({ email, initNAV, token }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_USER_BEGIN,
      });
      console.log(initNAV);
      let result = await axios.post(apiUrl + "manage/createUser", {
        email,
        initNAV,
        token,
      });
      let output = result.data;
      console.log(output);
      alert("Create Success");
      dispatch({
        type: CREATE_USER_SUCCESS,
        data: output,
      });
    } catch (err) {
      dispatch({
        type: CREATE_USER_FAILURE,
      });
      console.log(err);
      alert(_.get(err, "response.data.error", "ERROR"));
    }
  };
}

export function useCreateUser() {
  const dispatch = useDispatch();

  const { createUserPending } = useSelector(
    (state) => ({
      createUserPending: state.home.createUserPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(createUser(data));
    },
    [dispatch]
  );

  return {
    createUser: boundAction,
    createUserPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATE_USER_BEGIN:
      return {
        ...state,
        createUserPending: true,
      };

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserPending: false,
      };

    case CREATE_USER_FAILURE:
      return {
        ...state,
        createUserPending: false,
      };

    default:
      return state;
  }
}
