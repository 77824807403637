import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import BigNumber from "bignumber.js";
import Pagination from "@material-ui/lab/Pagination";
import DashboardChart from "./components/DashboardChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "components/CustomTabs/Tabs.js";
import { useFetchFund } from "./redux/fetchFund";
import { useFetchTransactions } from "./redux/fetchTransactions";
import CustomTable from "components/CustomTable/CustomTable";
const Fund = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [tabValue, setTabValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const { fetchFund, fetchFundPending, fundData } = useFetchFund();
  const {
    fetchTransactions,
    transactionsData,
    fetchTransactionsPending,
    totalPage,
  } = useFetchTransactions();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const mapping = { 0: "A", 1: "B", 2: "C" };
  useEffect(() => {
    fetchFund({ type: mapping[tabValue] });
    const id = setInterval(() => {
      fetchFund({ type: mapping[tabValue] });
    }, 10000);
    return () => clearInterval(id);
  }, [tabValue]);

  useEffect(() => {
    fetchTransactions({ type: mapping[tabValue], page });
    const id = setInterval(() => {
      fetchTransactions({ type: mapping[tabValue], page });
    }, 10000);
    return () => clearInterval(id);
  }, [page, tabValue]);

  const renderCard = (img, header, num) => {
    return (
      <div
        className="card"
        style={{
          background: "white",
          margin: 0,
          height: 200,
          position: "relative",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <img
              src={require("assets/img/" + img).default}
              style={{ height: 40, width: 40, objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              className="cardHeader"
              style={{ margin: "20px 0", fontSize: 14 }}
            >
              {header}
            </div>
            <div>
              <span
                className={"cardLgValue"}
                style={{ position: "absolute", bottom: 30 }}
              >
                {num}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderFundContent = (coin, type) => {
    const net = `$ ${
      fundData
        ? new BigNumber(
            _.get(fundData, `NAV.class${mapping[tabValue]}`, 0)
          ).toFormat(0)
        : "-"
    }`;
    const data =
      fundData &&
      _.map(fundData.dailyNAV, (d) => {
        return { x: new Date(d.timestamp * 1000), y: d.totalNAV };
      });

    return (
      <div className="card">
        <div className="cardHeader">Fund OverView</div>

        <Grid container spacing={2} style={{ margin: "0 auto", maxWidth: 800 }}>
          <Grid item xs={12} sm={3}>
            {renderCard("coin.png", "Base Currency for Fund", coin)}
          </Grid>
          <Grid item xs={12} sm={3}>
            {renderCard("asset.png", "Total Net Assets(USDT)", net)}
          </Grid>
          <Grid item xs={12} sm={3}>
            {renderCard("calandar.png", "Fund inception Date", "1.6.2021")}
          </Grid>
          <Grid item xs={12} sm={3}>
            {renderCard("currency.png", "Investment Style", type)}
          </Grid>
        </Grid>
        <div className="cardHeader">Balance</div>
        <div style={{ height: 400 }}>
          <DashboardChart data={data} />
        </div>
        <Pagination
          color="primary"
          count={totalPage}
          page={page}
          onChange={(event, value) => {
            setPage(value);
          }}
        />
        <CustomTable
          headers={[
            "account",
            "date",
            "pair",
            "side",
            "type",
            "execAmount",
            "execPrice",
            "from",
            "orderType",
          ]}
          contents={
            transactionsData &&
            transactionsData.map((d, index) => [
              d.account,
              d.date,
              d.pair,
              d.side,
              d.type,
              d.execAmount,
              d.execPrice,
              d.from,
              d.orderType,
            ])
          }
        />
      </div>
    );
  };
  return (
    <div>
      <div className="title">Fund</div>
      <div className="subTitle">
        Get summary of your weekly online transactions here.
      </div>
      <Tabs
        tabIndex={tabValue}
        handleChange={handleTabChange}
        tabs={[
          {
            label: "Class - A",
            content: renderFundContent("USDT", "Stable Fund"),
          },
          {
            label: "Class - B",
            content: renderFundContent("BTC", "Token Fund"),
          },
          {
            label: "Class - C",
            content: renderFundContent("ALT", "Token Fund"),
          },
        ]}
      />
    </div>
  );
};

export default Fund;
