import Web3 from "web3";
import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  HOME_CONNECT_WALLET_BEGIN,
  HOME_CONNECT_WALLET_SUCCESS,
  HOME_CONNECT_WALLET_FAILURE,
  HOME_DISCONNECT_WALLET_BEGIN,
  HOME_DISCONNECT_WALLET_SUCCESS,
  HOME_DISCONNECT_WALLET_FAILURE,
  HOME_REQUEST_LOGIN_BEGIN,
  HOME_REQUEST_LOGIN_SUCCESS,
  HOME_REQUEST_LOGIN_FAILURE,
} from "./constants";
import axios from "axios";
import { apiUrl } from "features/configure";
import lscache from "lscache";
import _ from "lodash";
export function login({ email, fa, verifyCode }) {
  return async (dispatch) => {
    dispatch({ type: HOME_CONNECT_WALLET_BEGIN });
    try {
      let result = await axios.post(apiUrl + "auth/login", {
        email,
        token: fa,
        verifyCode,
      });
      lscache.set("token", result.data.jwtToken, 60 * 24 * 30);
      dispatch({
        type: HOME_CONNECT_WALLET_SUCCESS,
        data: {
          auth: result.data.jwtToken,
          user: { auth: result.data.jwtToken },
        },
      });
    } catch (error) {
      dispatch({
        type: HOME_CONNECT_WALLET_FAILURE,
        data: _.get(error, "response.data.error", "Error"),
      });
    }
  };
}

export function request({ email }) {
  return async (dispatch) => {
    dispatch({ type: HOME_REQUEST_LOGIN_BEGIN });
    try {
      let result = await axios.post(apiUrl + "auth/request", {
        email,
      });
      dispatch({
        type: HOME_REQUEST_LOGIN_SUCCESS,
        data: result.data,
      });
    } catch (error) {
      dispatch({
        type: HOME_REQUEST_LOGIN_FAILURE,
        data: _.get(error, "response.data.error", "Error"),
      });
    }
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch({ type: HOME_DISCONNECT_WALLET_BEGIN });
    try {
      lscache.remove("token");
      dispatch({
        type: HOME_DISCONNECT_WALLET_SUCCESS,
        data: true,
      });
    } catch (error) {
      dispatch({ type: HOME_DISCONNECT_WALLET_FAILURE });
    }
  };
}

export function useAuth() {
  const dispatch = useDispatch();
  const {
    auth,
    user,
    error,
    connectWalletPending,
    disconnectWalletPending,
    verifyPending,
    verifyData,
  } = useSelector(
    (state) => ({
      auth: state.home.auth,
      user: state.home.user,
      error: state.home.error,
      verifyData: state.home.verifyData,
      verifyPending: state.home.verifyPending,
      connectWalletPending: state.home.connectWalletPending,
      disconnectWalletPending: state.home.disconnectWalletPending,
    }),
    shallowEqual
  );
  const boundAction = useCallback((data) => dispatch(login(data)), [dispatch]);
  const boundAction2 = useCallback(
    (data) => dispatch(logout(data)),
    [dispatch]
  );
  const requestAction = useCallback(
    (data) => dispatch(request(data)),
    [dispatch]
  );

  return {
    auth,
    user,
    error,
    verifyData,
    connectWalletPending,
    disconnectWalletPending,
    verifyPending,
    login: boundAction,
    logout: boundAction2,
    request: requestAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CONNECT_WALLET_BEGIN:
      return {
        ...state,
        connectWalletPending: true,
      };

    case HOME_CONNECT_WALLET_SUCCESS:
      return {
        ...state,
        auth: action.data.auth,
        user: action.data.user,
        connectWalletPending: false,
        error: "",
      };

    case HOME_CONNECT_WALLET_FAILURE:
      return {
        ...state,
        connectWalletPending: false,
        error: action.data,
      };

    case HOME_REQUEST_LOGIN_BEGIN:
      return {
        ...state,
        verifyPending: true,
      };

    case HOME_REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        verifyData: action.data,
        verifyPending: false,
        error: "",
      };

    case HOME_REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        verifyPending: false,
        error: action.data,
      };
    case HOME_DISCONNECT_WALLET_BEGIN:
      return {
        ...state,
        disconnectWalletPending: true,
      };

    case HOME_DISCONNECT_WALLET_SUCCESS:
      return {
        ...state,
        auth: null,
        disconnectWalletPending: false,
      };
    case HOME_DISCONNECT_WALLET_FAILURE:
      return {
        ...state,
        disconnectWalletPending: false,
      };
    default:
      return state;
  }
}
