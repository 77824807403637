import { reducer as authReducer } from "./auth";
import { reducer as fetchDashboardReducer } from "./fetchDashboard";
import { reducer as fetchFundReducer } from "./fetchFund";
import { reducer as fetchTransactionsReducer } from "./fetchTransactions";
import { reducer as fetchInternalReducer } from "./fetchInternal";
import { reducer as updateInternalReducer } from "./updateInternal";
import { reducer as createUserReducer } from "./createUser";
import lscache from "lscache";
const reducers = [
  authReducer,
  fetchDashboardReducer,
  fetchFundReducer,
  fetchTransactionsReducer,
  fetchInternalReducer,
  updateInternalReducer,
  createUserReducer,
];

const initialState = {
  auth: lscache.get("token"),
  user: {},
};

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
