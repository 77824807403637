import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import BigNumber from "bignumber.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useUpdateInternal, useFetchInternal } from "./redux/hooks";
import CustomTable from "components/CustomTable/CustomTable";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import Button from "components/CustomButtons/Button";
import { useAuth } from "features/home/redux/hooks";

const Internal = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [inputValue, setInputValue] = React.useState({});
  const { fetchInternal, fetchInternalPending, internalData } =
    useFetchInternal();
  const { updateInternal, updateInternalPending } = useUpdateInternal();
  const { auth } = useAuth();

  useEffect(() => {
    fetchInternal({});
    const id = setInterval(() => {
      fetchInternal({});
    }, 10000);
    return () => clearInterval(id);
  }, []);

  const update = async (classType, sharePrice) => {
    if (!sharePrice) return;
    await updateInternal({ classType, sharePrice, token: auth });
    fetchInternal({});
    setInputValue({ ...inputValue, [classType]: "" });
  };

  return (
    <div>
      <div className="title">Internal</div>
      <div className="subTitle"></div>
      <div className="card">
        <CustomTable
          headers={[
            "Class",
            "Raw Share Price",
            "Raw NAV",
            "Growth",
            "Last Client Share Price",
            "Adjusted Share Growth",
            "Adjusted Share Price",
            "Adjusted NAV",
          ]}
          contents={
            internalData
              ? internalData.map((d, index) => [
                  d.classType,
                  parseFloat(d.sharePrice).toFixed(2),
                  parseFloat(d.totalNAV).toFixed(2),
                  d.growth,
                  parseFloat(d.adjustedSharePrice).toFixed(2),
                  <CustomOutlinedInput
                    value={inputValue[d.classType]}
                    placeholder="%"
                    onChange={(e) =>
                      setInputValue({
                        ...inputValue,
                        [d.classType]: e.target.value,
                      })
                    }
                  />,
                  inputValue[d.classType]
                    ? parseFloat(
                        ((100 + parseFloat(inputValue[d.classType])) *
                          d.sharePrice) /
                          100
                      ).toFixed(2)
                    : "-",
                  inputValue[d.classType]
                    ? parseFloat(
                        ((100 + parseFloat(inputValue[d.classType])) *
                          d.sharePrice *
                          d.totalShares) /
                          100
                      ).toFixed(2)
                    : "-",
                  <Button
                    color="second"
                    onClick={() =>
                      update(
                        d.classType,
                        parseFloat(
                          ((100 + parseFloat(inputValue[d.classType])) *
                            d.sharePrice) /
                            100
                        )
                      )
                    }
                  >
                    update
                  </Button>,
                ])
              : []
          }
        />
      </div>
    </div>
  );
};

export default Internal;
