import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  UPDATE_INTERNAL_BEGIN,
  UPDATE_INTERNAL_SUCCESS,
  UPDATE_INTERNAL_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import axios from "axios";
export function updateInternal({ classType, sharePrice, token }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_INTERNAL_BEGIN,
      });
      console.log(classType, sharePrice);
      let result = await axios.post(apiUrl + "manage/updateSharePrice", {
        classType,
        sharePrice,
        token,
      });
      let output = result.data;
      console.log(output);
      dispatch({
        type: UPDATE_INTERNAL_SUCCESS,
        data: output,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_INTERNAL_FAILURE,
      });
      console.log(err);
    }
  };
}

export function useUpdateInternal() {
  const dispatch = useDispatch();

  const { updateInternalPending } = useSelector(
    (state) => ({
      updateInternalPending: state.home.updateInternalPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(updateInternal(data));
    },
    [dispatch]
  );

  return {
    updateInternal: boundAction,
    updateInternalPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_INTERNAL_BEGIN:
      return {
        ...state,
        updateInternalPending: true,
      };

    case UPDATE_INTERNAL_SUCCESS:
      return {
        ...state,
        updateInternalPending: false,
      };

    case UPDATE_INTERNAL_FAILURE:
      return {
        ...state,
        updateInternalPending: false,
      };

    default:
      return state;
  }
}
