import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import Social from "features/home/components/Social";
import DashboardChart from "./components/DashboardChart";
import { default as DashboardPieChart } from "./components/DashboardPieChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useFetchDashboard } from "./redux/fetchDashboard";
import _ from "lodash";
const Intro = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));

  const { fetchDashboard, fetchDashboardPending, data } = useFetchDashboard();

  useEffect(() => {
    fetchDashboard();
    const id = setInterval(() => {
      fetchDashboard();
    }, 10000);
    return () => clearInterval(id);
  }, []);
  const colors = { A: "#0B308E", B: "rgb(243, 157, 26)", C: "#5c5c5c" };
  const NAV = {
    A: new BigNumber(_.get(data, "NAV.classA", "0")),
    B: new BigNumber(_.get(data, "NAV.classB", "0")),
    C: new BigNumber(_.get(data, "NAV.classC", "0")),
  };
  const totalNAV = _.get(data, "totalNAV", 1);
  const initNAV = {
    A: new BigNumber(_.get(data, "initialNAV.classA", "0")),
    B: new BigNumber(_.get(data, "initialNAV.classB", "0")),
    C: new BigNumber(_.get(data, "initialNAV.classC", "0")),
  };
  const totalInitNAV = _.get(data, "initialTotalNAV", 1);
  const NAVPersent = {
    A: (NAV["A"] * 100) / totalNAV,
    B: (NAV["B"] * 100) / totalNAV,
    C: (NAV["C"] * 100) / totalNAV,
  };
  const initNAVPersent = {
    A: (initNAV["A"] * 100) / totalNAV,
    B: (initNAV["B"] * 100) / totalNAV,
    C: (initNAV["C"] * 100) / totalNAV,
  };

  const currentBalance = totalNAV;
  const initBalance = totalInitNAV;
  const varity = (currentBalance - initBalance) / initBalance;
  const renderNAVNumber = (type) => {
    return (
      <div style={{ margin: "40px 10px" }}>
        <div className="cardSubHeader">Class - {type}</div>
        <div
          style={{
            backgroundColor: colors[type],
            height: 5,
            borderRadius: 5,
            margin: "5px 0",
          }}
        ></div>
        <span className="cardValue">$ {NAV[type].toFormat(0)}</span>
        <div className="cardLgValue" style={{ float: "right" }}>
          {parseFloat((NAV[type] * 100) / totalNAV).toFixed(1 )}%
        </div>
      </div>
    );
  };

  const renderInitNAVNumber = (type) => {
    return (
      <Grid item xs={12} sm={4}>
        <div style={{ margin: "20px 10px" }}>
          <div className="cardSubHeader">Class - {type}</div>
          <div
            style={{
              backgroundColor: colors[type],
              height: 3,
              borderRadius: 5,
              margin: "5px 0",
            }}
          ></div>
          <span className="cardSubHeader">$ {initNAV[type].toFormat(0)}</span>
          <div className="label" style={{ float: "right" }}>
            {(initNAV[type] * 100) / totalInitNAV}%
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <div>
      <div className="title">Asset Infomation</div>
      <div className="subTitle">
        Get summary of your weekly online transactions here.
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div className="card">
            <div className="cardHeader">Fund Overview</div>
            <Grid container>
              <Grid item xs={12} sm={10}>
                {data && data.daily && (
                  <DashboardChart
                    data={_.map(data.daily, (d) => {
                      return { x: new Date(d.timestamp * 1000), y: d.totalNAV };
                    })}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <div className="card" style={{ textAlign: "right" }}>
                  <div className="cardContent">Current balance</div>
                  <div className="cardLgValue">
                    $ {new BigNumber(totalNAV).toFormat(0)}
                  </div>
                  <hr />
                  <div className="cardContent">Netincome</div>
                  <div className="cardGreenValue">
                    $ {new BigNumber(totalNAV - totalInitNAV).toFormat(0)}
                  </div>
                  <hr />
                  <div className="cardContent">Varity</div>
                  <div className="">
                    <span
                      className={
                        varity >= 0 ? "cardGreenValue" : "cardRedValue"
                      }
                    >
                      {parseFloat(varity * 100).toFixed(1)}%
                    </span>
                  </div>
                  <hr />
                  <div className="cardContent">initial capital</div>
                  <div className="cardRedValue">
                    $ {new BigNumber(totalInitNAV).toFormat(0)}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="card" style={{ minHeight: 350 }}>
            <div className="cardHeader">Current Stragety Chart</div>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ position: "relative" }}>
                {data && (
                  <DashboardPieChart
                    data={NAVPersent}
                    colors={colors}
                    total={totalNAV}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderNAVNumber("A")}
                {renderNAVNumber("B")}
                {renderNAVNumber("C")}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="card">
            <div className="cardHeader">Inital Asset Management </div>
            <Grid container>
              {renderInitNAVNumber("A")}
              {renderInitNAVNumber("B")}
              {renderInitNAVNumber("C")}
            </Grid>
          </div>
          <div className="card">
            <div className="cardHeader">Asset Admin</div>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} sm={5}>
                <Button color="secondary">
                  Contact Advisor
                  <KeyboardArrowRightIcon />
                </Button>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Grid container style={{ textAlign: "center" }}>
                  <Grid item xs={12} sm={4}>
                    <img src={require("assets/img/james.png").default} />
                    <br />
                    <div style={{ padding: 5 }}>James</div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <img src={require("assets/img/james.png").default} />
                    <br />
                    <div style={{ padding: 5 }}>James</div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <img src={require("assets/img/james.png").default} />
                    <br />
                    <div style={{ padding: 5 }}>James</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Intro;
