import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./styles";
import { useTheme } from "@material-ui/core/styles";
import ReorderIcon from "@material-ui/icons/Reorder";
import EqualizerIcon from "@material-ui/icons/Equalizer";
const useStyles = makeStyles(styles);

const checkSelected = (location, linkTo) => {
  if (linkTo.length < 5) return location == "#" + linkTo;
  return location.indexOf("#" + linkTo) >= 0;
};

const renderItem = (
  name,
  icon,
  linkTo,
  location,
  classes,
  hovered,
  setHovered
) => {
  const selected = checkSelected(location, linkTo);
  const imgName = icon + ".png";
  return (
    <ListItem
      button
      component={Link}
      to={linkTo}
      selected={selected}
      classes={{ root: classes.listItems }}
      disabled={!linkTo}
      onMouseOver={() => setHovered({ ...hovered, [name]: true })}
      onMouseOut={() => setHovered({ ...hovered, [name]: false })}
    >
      <img
        src={require("assets/img/" + imgName).default}
        style={{ width: 20, height: 20 }}
      />
      <span classes={{ root: classes.menuLink }}>{name}</span>
    </ListItem>
  );
};
const renderBlankTargetItem = (
  name,
  icon,
  linkTo,
  location,
  classes,
  hovered,
  setHovered
) => {
  const selected = checkSelected(location, linkTo);
  const imgName =
    icon + "_" + (selected || hovered[name] ? "green" : "white") + ".png";
  return (
    <ListItem
      button
      component={"a"}
      href={linkTo}
      target="_blank"
      classes={{ root: classes.listItems }}
      onMouseOver={() => setHovered({ ...hovered, [name]: true })}
      onMouseOut={() => setHovered({ ...hovered, [name]: false })}
    >
      {/* <img src={require('assets/img/' + imgName)} style={{ width: 20, height: 20 }} /> */}
      <span classes={{ root: classes.menuLink }}>{name}</span>
    </ListItem>
  );
};
const MenuItems = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [hovered, setHovered] = useState({});
  const theme = useTheme();
  let currentLocation = window.location.hash;
  return (
    <div style={{ overflowX: "hidden", height: "calc(100vh - 200px)" }}>
      {renderItem(
        "Dashboard",
        "dashboard",
        "/",
        currentLocation,
        classes,
        hovered,
        setHovered
      )}
      {renderItem(
        "Fund",
        "fund",
        "/fund",
        currentLocation,
        classes,
        hovered,
        setHovered
      )}
      {renderItem(
        "Price",
        "price",
        "/price",
        currentLocation,
        classes,
        hovered,
        setHovered
      )}

      {renderItem(
        "Report",
        "report",
        "/report",
        currentLocation,
        classes,
        hovered,
        setHovered
      )}
      {renderItem(
        "Settings",
        "settings",
        "/settings",
        currentLocation,
        classes,
        hovered,
        setHovered
      )}
      {renderItem(
        "Create",
        "create",
        "/createUser",
        currentLocation,
        classes,
        hovered,
        setHovered
      )}
    </div>
  );
};

export default MenuItems;
