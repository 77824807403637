import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import BigNumber from "bignumber.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useUpdateInternal,
  useFetchInternal,
  useCreateUser,
} from "./redux/hooks";
import CustomTable from "components/CustomTable/CustomTable";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import Button from "components/CustomButtons/Button";
import { useAuth } from "features/home/redux/hooks";

const CreateUser = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [email, setEmail] = React.useState("");
  const [inputValue, setInputValue] = React.useState({
    classA: "",
    classB: "",
    classC: "",
    classD: "",
  });
  const { fetchInternal, fetchInternalPending, internalData } =
    useFetchInternal();
  const { createUser, createUserPending } = useCreateUser();
  const { auth } = useAuth();

  useEffect(() => {
    fetchInternal({});
    const id = setInterval(() => {
      fetchInternal({});
    }, 10000);
    return () => clearInterval(id);
  }, []);

  const create = async () => {
    if (!email) {
      alert("Email is Null");
      return;
    }
    await createUser({ email, initNAV: inputValue, token: auth });
  };

  return (
    <div>
      <div className="title">Create User</div>

      <div className="card">
        <div style={{ flex: "row" }}>
          <div style={{ fontWeight: 700 }}>User Email: </div>
          <CustomOutlinedInput
            value={email}
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <CustomTable
          headers={[
            "Class",
            "Input NAV",
            "Last Client Share Price",
            "User Share Number",
          ]}
          contents={
            internalData
              ? internalData.map((d, index) => [
                  d.classType,
                  <CustomOutlinedInput
                    value={inputValue[d.classType]}
                    placeholder="$"
                    onChange={(e) =>
                      setInputValue({
                        ...inputValue,
                        [`class${d.classType}`]: e.target.value,
                      })
                    }
                  />,
                  parseFloat(d.sharePrice).toFixed(2),
                  inputValue[d.classType]
                    ? parseFloat(
                        parseFloat(inputValue[d.classType]) / d.sharePrice
                      ).toFixed(2)
                    : "-",
                ])
              : []
          }
        />
        <div style={{ textAlign: "center" }}>
          <Button onClick={() => create()} disabled={createUserPending}>
            Create
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
