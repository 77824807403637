import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_TVL_BEGIN,
  FETCH_TVL_SUCCESS,
  FETCH_TVL_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import axios from "axios";
export function fetchDashboard() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_TVL_BEGIN,
      });
      let dailyResult = await axios.get(apiUrl + "stats/daily/all");
      let result = await axios.get(apiUrl + "stats");

      let output = result.data;
      output.daily = dailyResult.data;
      console.log(output);
      dispatch({
        type: FETCH_TVL_SUCCESS,
        data: output,
      });
    } catch (err) {
      dispatch({
        type: FETCH_TVL_FAILURE,
      });
      console.log(err);
    }
  };
}

export function useFetchDashboard() {
  const dispatch = useDispatch();

  const { data, fetchDashboardPending, fetchDashboardDone } = useSelector(
    (state) => ({
      data: state.home.data,
      fetchDashboardPending: state.home.fetchDashboardPending,
      fetchDashboardDone: state.home.fetchDashboardDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchDashboard(data));
    },
    [dispatch]
  );

  return {
    data,
    fetchDashboard: boundAction,
    fetchDashboardDone,
    fetchDashboardPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_TVL_BEGIN:
      return {
        ...state,
        fetchDashboardPending: true,
      };

    case FETCH_TVL_SUCCESS:
      return {
        ...state,
        data: action.data,
        fetchDashboardDone: true,
        fetchDashboardPending: false,
      };

    case FETCH_TVL_FAILURE:
      return {
        ...state,
        fetchDashboardPending: false,
      };

    default:
      return state;
  }
}
